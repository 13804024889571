<template>
    <Layout>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestRemote"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname">Unit*</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="barrier.unit_id"
                            :options="mappedUserUnits"
                            :class="{
                                'is-invalid':
                                    submitted && $v.barrier.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.barrier.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.barrier.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="form-group">
                        <label for="ownerfname"
                            >Number of remote controls*</label
                        >
                        <b-form-select
                            id="ownerfname"
                            v-model="barrier.no_of_remotes"
                            name="ownerfname"
                            :options="drawings"
                            :class="{
                                'is-invalid':
                                    submitted &&
                                    $v.barrier.no_of_remotes.$error,
                            }"
                        ></b-form-select>
                        <div
                            v-if="submitted && $v.barrier.no_of_remotes.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.barrier.no_of_remotes.required"
                                >Please choose a value</span
                            >
                        </div>
                    </div>
                </div>
            </div>

            <submitButton />
        </form>
        <paymentModal
            ref="paymentWindow"
            page="Barrier Remote Control"
            :modalTitle="modalTitle"
            :adminFees="adminFees"
            :urgentFees="urgentFees"
            :securityDepFees="securityDepFees"
            :adminVatFees="adminVatFees"
            :serviceFees="serviceFees"
            :updatedAdminFees="updatedAdminFees"
            :updatedAdminVatFees="updatedAdminVatFees"
        />
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import alertMixin from "../../../mixins/alertMixin";
import paymentModal from "../payment/paymentModal.vue";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],
    components: {
        Layout,
        paymentModal,
        submitButton,
    },
    validations: {
        barrier: {
            unit_id: { required },
            no_of_remotes: { required },
        },
    },
    mounted() {
        this.getTermsConditions();
    },
    data() {
        return {
            payment: "",
            updatedAdminFees: "",
            updatedAdminVatFees: "",
            submitted: false,
            adminFees: "",
            adminVatFees: "",
            securityDepFees: "",
            urgentFees: "",
            serviceFees: "",
            modalTitle: "",
            drawings: [
                { value: null, text: "Choose a number" },
                { value: "1", text: "1" },
                { value: "2", text: "2" },
                { value: "3", text: "3" },
            ],
            barrier: {
                unit_id: "",
                no_of_remotes: "",
            },
        };
    },
    methods: {
        requestRemote() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}barrier-remote-controls?token=${localStorage.cs_user_token}`,
                            this.barrier
                        )
                        .then((res) => {
                            console.log(res.data.response);
                            this.updatedAdminFees =
                                this.barrier.no_of_remotes * this.adminFees;
                            this.updatedAdminVatFees =
                                this.adminVatFees * this.barrier.no_of_remotes;
                            this.modalTitle =
                                "Payment for  " +
                                " " +
                                res.data.response.ref_no;
                            loader.hide();
                            this.$refs.paymentWindow.showModal();
                            this.submitted = false;
                            this.barrier.unit_id = "";
                            this.barrier.no_of_remotes = "";
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
        getTermsConditions() {
            if (localStorage.cs_user_token) {
                let payload = {
                    request_type: REQUEST_TYPES.find(
                        (x) => x.type === "Barrier Remote Control"
                    ).id,
                };
                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        this.adminFees = res.data.response.service_fees;
                        this.serviceFees = res.data.response.service_fees;

                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                        this.securityDepFees =
                            res.data.response.security_deposit;
                    });
            }
        },
    },
    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
};
</script>
