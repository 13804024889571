<template>
    <div>
        <b-modal
            no-close-on-backdrop
            id="modal-backdrop"
            :title="modalTitle"
            ok-title="Proceed"
            title-class="font-18"
            @ok="proceed"
            @cancel="onClose"
        >
            <!-- :ok-disabled="service_fees_payment_mode == ''" -->
            <div v-if="page === 'Immigration'">
                <h6>Notes:</h6>
                <p>
                    1. AED {{ adminFees }} Admin fees, payable by online, cash
                    or manager's cheque addressed to FALCONCITY OF WONDERS
                    L.L.C.
                </p>
                <p>
                    2. Urgent processing (2 working days) AED
                    {{ urgentFees }} urgent fees.
                </p>
                <h6>Choose payment options</h6>
                <p v-if="!isUrgent">Admin Fees : {{ adminFees }}</p>
                <p v-if="!isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees }}
                </p>
                <p v-if="isUrgent">Admin Fees : {{ adminFees + urgentFees }}</p>
                <p v-if="isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees + urgentFees }}
                </p>

                <p v-if="urgentFees != 0">Urgent Fees : {{ urgentFees }}</p>

                <b-form-checkbox
                    v-if="urgentFees != 0"
                    id="checkbox-1"
                    @input="changeStatus()"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                    Urgent Fees
                </b-form-checkbox>

                <b-form-radio-group
                    v-model="service_fees_payment_mode"
                    :options="options"
                    name="radio-inline"
                ></b-form-radio-group>
            </div>

            <div v-if="page === 'Title Deed'">
                <h6>Notes:</h6>
                <p>
                    AED {{ serviceFees }} Admin fees, payable by online, cash or
                    manager's cheque addressed to FALCONCITY OF WONDERS L.L.C.
                </p>

                <p>
                    2. Urgent processing (2 working days) AED
                    {{ urgentFees }} urgent fees.
                </p>
                <h6>Choose payment options</h6>
                <p v-if="!isUrgent">Admin Fees : {{ adminFees }}</p>
                <p v-if="!isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees }}
                </p>
                <p v-if="isUrgent">Admin Fees : {{ adminFees + urgentFees }}</p>
                <p v-if="isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees + urgentFees }}
                </p>

                <p v-if="urgentFees != 0">Urgent Fees : {{ urgentFees }}</p>

                <b-form-checkbox
                    v-if="urgentFees != 0"
                    id="checkbox-1"
                    @input="changeStatus()"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                    Urgent Fees
                </b-form-checkbox>

                <b-form-radio-group
                    v-model="service_fees_payment_mode"
                    :options="options"
                    name="radio-inline"
                ></b-form-radio-group>
            </div>

            <div v-if="page === 'Amendment'">
                <h6>Notes:</h6>
                <ol>
                    <li>
                        AED {{ adminFees }} Admin fees, payable by online, cash
                        or manager's cheque addressed to FALCONCITY OF WONDERS
                        L.L.C.
                    </li>
                    <li>
                        AED {{ securityDepFees }} Security Deposit, refundable
                        after completing the transfer procedure in DLD.
                    </li>
                    <li>
                        Urgent processing (2 working days) AED
                        {{ urgentFees }} urgent fees.
                    </li>
                </ol>
                <h6>Choose payment options</h6>
                <p v-if="!isUrgent">Admin Fees : {{ adminFees }}</p>
                <p v-if="!isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees }}
                </p>
                <p v-if="isUrgent">Admin Fees : {{ adminFees + urgentFees }}</p>
                <p v-if="isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees + urgentFees }}
                </p>

                <b-form-radio-group
                    :options="options"
                    name="radio-inline"
                    v-model="service_fees_payment_mode"
                    :state="serviceFeeState"
                >
                    <b-form-invalid-feedback :state="serviceFeeState"
                        >Please select one</b-form-invalid-feedback
                    >
                </b-form-radio-group>
                <!-- {{ service_fees_payment_mode }} -->
                <br />
                <p v-if="urgentFees != 0">Urgent Fees : {{ urgentFees }}</p>

                <b-form-checkbox
                    v-if="urgentFees != 0"
                    id="checkbox-1"
                    @input="changeStatus()"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                    Urgent Fees
                </b-form-checkbox>
                <br />

                <p>Security Deposit Fees : {{ securityDepFees }}</p>

                <b-form-radio-group
                    :options="securityDepositPaymentOptions"
                    v-model="security_deposit_payment_mode"
                    :state="securityFeeState"
                    name="radio-inline2"
                >
                    <b-form-invalid-feedback :state="securityFeeState"
                        >Please select one</b-form-invalid-feedback
                    >
                </b-form-radio-group>
                <!-- {{ security_deposit_payment_mode }} -->
            </div>

            <div v-if="page === 'Interim Registration'">
                <h6>Notes:</h6>
                <ol>
                    <li>
                        AED {{ serviceFees }} Admin fees, payable by online,
                        cash or manager cheque addressed to FALCONCITY OF
                        WONDERS L.L.C.
                    </li>
                    <li>
                        AED {{ securityDepFees }} Security Deposit, refundable
                        after completing the transfer procedure in DLD.
                    </li>
                    <li>
                        Urgent processing (2 working days) AED
                        {{ urgentFees }} urgent fees.
                    </li>
                </ol>
                <h6>Choose payment options</h6>
                <p v-if="!isUrgent">Admin Fees : {{ adminFees }}</p>
                <p v-if="!isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees }}
                </p>
                <p v-if="isUrgent">Admin Fees : {{ adminFees + urgentFees }}</p>
                <p v-if="isUrgent">
                    Admin Fees with 5% VAT : {{ adminVatFees + urgentFees }}
                </p>

                <b-form-radio-group
                    :options="options"
                    v-model="service_fees_payment_mode"
                    name="radio-inline"
                ></b-form-radio-group>
                <br />
                <p v-if="urgentFees != 0">Urgent Fees : {{ urgentFees }}</p>
                <b-form-checkbox
                    v-if="urgentFees != 0"
                    id="checkbox-1"
                    @input="changeStatus()"
                    name="checkbox-1"
                    value="accepted"
                    unchecked-value="not_accepted"
                >
                    Urgent Fees
                </b-form-checkbox>
                <br />
            </div>

            <div v-if="page === 'NOC Revalidation'">
                <h6>Notes:</h6>
                <ol>
                    <li>
                        AED {{ adminFees }} Admin fees, payable by online, cash
                        or manager's cheque addressed to FALCONCITY OF WONDERS
                        L.L.C.
                    </li>
                    <li v-if="securityDepFees">
                        AED {{ securityDepFees }} Security Deposit, refundable
                        after completing the transfer procedure in DLD.
                    </li>
                    <li v-if="urgentFees">
                        Urgent processing (2 working days) AED
                        {{ urgentFees }} urgent fees.
                    </li>
                </ol>
                <h6>Choose payment options</h6>
                <p>Admin Fees : {{ adminFees }}</p>
                <p>Admin Fees with 5% VAT : {{ adminVatFees }}</p>
                <br />
                <b-form-radio-group
                    :options="options"
                    v-model="service_fees_payment_mode"
                    name="radio-inline"
                ></b-form-radio-group>
            </div>

            <div v-if="page === 'Built Drawings'">
                <h6>Notes:</h6>
                <ol>
                    <li>
                        AED {{ adminFees }} Admin fees, payable by online, cash
                        or manager's cheque addressed to FALCONCITY OF WONDERS
                        L.L.C.
                    </li>
                    <li v-if="securityDepFees">
                        AED {{ securityDepFees }} Security Deposit, refundable
                        after completing the transfer procedure in DLD.
                    </li>
                    <li v-if="urgentFees">
                        Urgent processing (2 working days) AED
                        {{ urgentFees }} urgent fees.
                    </li>
                </ol>

                <h6>Choose payment options</h6>
                <p>Admin Fees : {{ updatedAdminFees }}</p>
                <p>Admin Fees with 5% VAT : {{ updatedAdminVatFees }}</p>
                <br />
                <b-form-radio-group
                    :options="options"
                    name="radio-inline"
                    v-model="service_fees_payment_mode"
                ></b-form-radio-group>
            </div>

            <div v-if="page === 'Trash Bin'">
                <h6>Notes:</h6>
                <ol>
                    <li>
                        AED {{ adminFees }} Admin fees, payable by online, cash
                        or manager's cheque addressed to FALCONCITY OF WONDERS
                        L.L.C.
                    </li>
                    <li v-if="securityDepFees">
                        AED {{ securityDepFees }} Security Deposit, refundable
                        after completing the transfer procedure in DLD.
                    </li>
                    <li v-if="urgentFees">
                        Urgent processing (2 working days) AED
                        {{ urgentFees }} urgent fees.
                    </li>
                </ol>

                <h6>Choose payment options</h6>
                <p>Admin Fees : {{ updatedAdminFees }}</p>
                <p>Admin Fees with 5% VAT : {{ updatedAdminVatFees }}</p>
                <br />
                <b-form-radio-group
                    :options="options"
                    name="radio-inline"
                    v-model="service_fees_payment_mode"
                ></b-form-radio-group>
            </div>

            <div v-if="page === 'Barrier Remote Control'">
                <h6>Notes:</h6>
                <ol>
                    <li>
                        AED {{ serviceFees }} Admin fees, payable by online,
                        cash or manager's cheque addressed to FALCONCITY OF
                        WONDERS L.L.C.
                    </li>
                    <li v-if="securityDepFees">
                        AED {{ securityDepFees }} Security Deposit, refundable
                        after completing the transfer procedure in DLD.
                    </li>
                    <li v-if="urgentFees">
                        Urgent processing (2 working days) AED
                        {{ urgentFees }} urgent fees.
                    </li>
                </ol>

                <h6>Choose payment options</h6>
                <p>Admin Fees : {{ updatedAdminFees }}</p>
                <p>Admin Fees with 5% VAT : {{ updatedAdminVatFees }}</p>
                <br />
                <b-form-radio-group
                    :options="options"
                    v-model="service_fees_payment_mode"
                    name="radio-inline"
                ></b-form-radio-group>
            </div>
        </b-modal>
    </div>
</template>
<script>
import alertMixin from "../../../mixins/alertMixin";
import { REQUEST_TYPES, BASE_URL } from "../../common";
import axios from "axios";

export default {
    mixins: [alertMixin],

    props: [
        "requestId",
        "referenceNumber",
        "page",
        "modalTitle",
        "adminFees",
        "adminVatFees",
        "urgentFees",
        "serviceFees",
        "securityDepFees",
        "updatedAdminFees",
        "updatedAdminVatFees",
    ],
    data() {
        return {
            test: true,
            isUrgent: false,
            service_fees_payment_mode: "",
            security_deposit_payment_mode: 0,
            options: [
                // { text: "Debit/Credit Card", value: 1 },
                // { text: "Cash", value: 2 },
                // { text: `Manager's Cheque`, value: 3 },
            ],
            securityDepositPaymentOptions: [
                { text: "Cash", value: 2 },
                { text: "Cheque", value: 3 },
            ],
        };
    },
    methods: {
        showModal() {
            this.$bvModal.show("modal-backdrop");
        },
        changeStatus() {
            console.log("clicked");
            this.isUrgent = !this.isUrgent;
        },
        onClose() {
            console.log("modal closed");
            this.page = "";
            this.modalTitle = "";
            this.adminFees = "";
            this.adminVatFees = "";
            this.urgentFees = "";
            this.serviceFees = "";
            this.securityDepFees = "";
            this.updatedAdminFees = "";
            this.updatedAdminVatFees = "";
            this.isUrgent = false;
            this.service_fees_payment_mode = "";
            this.security_deposit_payment_mode = 0;
        },
        proceed() {
            console.log(this.page);
            if (this.service_fees_payment_mode === 1) {
                let total = 0;
                if (this.isUrgent) {
                    total = this.adminVatFees + this.urgentFees;
                    total = total * 100;
                } else {
                    total = this.adminVatFees;
                    total = total * 100;
                }
                if (
                    this.page === "Built Drawings" ||
                    this.page === "Trash Bin" ||
                    this.page === "Barrier Remote Control"
                ) {
                    total = this.updatedAdminVatFees;
                    total = total * 100;
                }

                let requestTypeId = REQUEST_TYPES.find(
                    (x) => x.type === this.page
                ).id;

                let ref = "";
                ref = String(requestTypeId) + String(this.requestId);

                let description =
                    "type-request#request_type-" +
                    requestTypeId +
                    "#request_id-" +
                    this.requestId;

                let payload = {
                    amount: total,
                    command: "PURCHASE",
                    currency: "AED",
                    customer_email: JSON.parse(localStorage.user_info).email,
                    language: "en",
                    merchant_reference: ref,
                    order_description: description,
                };
                console.log("the payload for signature", payload);
                this.$store.dispatch("apidata/setPaymentInfo", payload);
                this.isUrgent = false;
                this.service_fees_payment_mode = "";
                this.security_deposit_payment_mode = 0;
                localStorage.setItem("payFort", JSON.stringify(payload));
                this.$router.push({
                    path: `/new/payment/${requestTypeId}/${this.requestId}`,
                });
            }
            //cash payment
            else {
                let requestTypeId = REQUEST_TYPES.find(
                    (x) => x.type === this.page
                ).id;

                let description =
                    "type-request#request_type-" +
                    requestTypeId +
                    "#request_id-" +
                    this.requestId;

                let payload = {
                    amount: 0,
                    card_number: "",
                    command: "",
                    currency: "",
                    customer_ip: "",
                    eci: "",
                    expiry_date: "",
                    fort_id: 0,
                    language: "",
                    merchant_reference: 0,
                    order_description: description,
                    payment_option: "",
                    request_id: requestTypeId,
                    request_type: this.requestId,
                    security_deposit_payment_mode:
                        this.security_deposit_payment_mode,
                    service_fees_payment_mode: this.service_fees_payment_mode,
                    urgent_fees_payment_mode: this.service_fees_payment_mode,
                };
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}update-payment-info?token=${localStorage.cs_user_token}`,
                            payload
                        )
                        // eslint-disable-next-line no-unused-vars
                        .then((res) => {
                            loader.hide();
                            this.successMsg(
                                " Your request is successfully submitted. Kindly deposit the cash/cheque",
                                "Thank you"
                            );
                            this.isUrgent = false;
                            this.service_fees_payment_mode = "";
                            this.security_deposit_payment_mode = 0;
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                            this.isUrgent = false;
                            this.service_fees_payment_mode = "";
                            this.security_deposit_payment_mode = 0;
                        });
                }
            }
        },
    },
    computed: {
        serviceFeeState() {
            return Boolean(this.service_fees_payment_mode);
        },
        securityFeeState() {
            return Boolean(this.security_deposit_payment_mode);
        },
    },
};
</script>
