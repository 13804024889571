import Swal from "sweetalert2";

export default {
    data() {
        return {};
    },
    methods: {
        showError(message) {
            Swal.fire({
                icon: "error",
                title: message,
            });
        },
        successMsg(title, message) {
            Swal.fire(title, message, "success");
        },
        errorMsg(title) {
            Swal.fire({
                position: "top-end",
                icon: "error",
                title: title,
                showConfirmButton: false,
                timer: 3000,
            });
        },
        successPopUp(title) {
            Swal.fire({
                toast: true,
                position: "top-right",
                icon: "success",
                title: title,
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
            });
        },
        resetFields() {
            Object.assign(this.$data, this.$options.data.call(this));
        },
    },
    mounted() {},
};
